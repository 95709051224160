import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { animations } from "ack-angular-fx";
import { GlobalService } from '../../../core/services/global.service';
import { ProfileService } from '../../../core/services/profile.service';
import { Profile } from '../../../models/Profile';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Configuration } from '../../../app.constants';
import { D3Graph } from '../../../models/shared/graph/d3Graph';
import { TitleEventsEmitter } from '../../../core/events/title.events';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { EventService } from '../../../core/services/event.service';
import { Event } from '../../../models/Event';
import { Location } from '@angular/common'

@Component({
  selector: 'member',
  templateUrl: 'member.component.html',
  styleUrls: ['member.component.css'],
  animations: animations
})

export class MemberComponent extends BaseComponent implements OnInit, OnDestroy {
  //@ViewChild("questions") questions: ProfileQuestionsComponent

  profileId: number;
  profile: Profile;

  events: Event[];

  graphMode: string = "1";
  isModalInviteVisible = false;
  filterSettingsMyEvents: FilterSettings;
  gridSettingsMyEvents: GridSettings;

  constructor(
    private profileService: ProfileService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private msg: NzMessageService,
    private titleEventsEmitter: TitleEventsEmitter,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {
    super();

    this.filterSettingsMyEvents = new FilterSettings();
    this.filterSettingsMyEvents.gender = 0;
    this.filterSettingsMyEvents.distance = 0;
    this.filterSettingsMyEvents.profileId = this.profileId;

    this.gridSettingsMyEvents = new GridSettings();
    this.gridSettingsMyEvents.pageSize = 3;
    this.gridSettingsMyEvents.pageIndex = 0;
    this.gridSettingsMyEvents.sortField = "Created";
    this.gridSettingsMyEvents.sortAsc = false;
  }

  ngOnInit() {
    
    super.ngOnInit();

    //this.setLoading(true);

    this.route.params.subscribe(params => {
      this.profileId = params['id'];
      this.profileService
        .Get(this.profileId)
        .subscribe(
          data => {
            
            this.profile = data;
            if (this.profile.status == 4 && this.currentProfile.userLevel!=3) { this.nav('/members'); }
            
            this.filterSettingsMyEvents.profileId = this.profileId;
            this.setLoading(false);
            this.isLoading = false;
            this.eventService.GetAttendingForSettings(this.filterSettingsMyEvents, this.gridSettingsMyEvents).subscribe(data => {
              this.events = data.filter(t => t.privacyStatus != 2);

              
            });
          },
          error => console.log(error),
          () => { }
        );
    });

    this.titleEventsEmitter.broadcastEventTitleChanged("Member");
  }

  showModalInvite(): void {
    this.isModalInviteVisible = true;
  }

  handleModalInviteOk() {
    this.isModalInviteVisible = false;
  }

  loadData() {
    //this.setLoading(true);
    
  }

  goBack() {
    this.location.back()
  }

  message() {
    //this.nav('/messages?to='+, { state: { profileId: this.profile.id.toString() } });
    this.nav('/messages?to=' + this.profile.id.toString() );
  }

  //onResize(event) {
  //  //if (!this.isLoading) {
  //  //  console.log('resize');
  //  //  //this.setLoading(true);
  //  //  this.loadGraph();
  //  //}
  //}

  public getDistance(p: Profile) {
    return (this.globalService.calcDistanceKM(p.latitude, p.longitude, this.currentProfile.latitude, this.currentProfile.longitude) * 0.621371).toFixed(0) + ' miles away'
  }

  //tabSelectedChange($event) {
  //  this.cd.detectChanges();
  //}

  getImageUrl = (p: Profile, size: number): string => { return this.globalService.getProfileImageUrl(p, size); }

}
