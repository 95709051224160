import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProfileService } from '../../core/services/profile.service';
import { Profile } from '../../models/Profile';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { GridSettings } from '../../models/shared/grids/gridSettings';
import { QuestionService } from '../../core/services/question.service';
import { Question } from '../../models/Question';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { animations } from 'ack-angular-fx';
import { BaseComponent } from '../shared/base-component/base-component.component';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Event as RouterEvent } from '@angular/router';

const FILTER_STATE_KEY = 'members.filter.state';
const RECORD_COUNT_KEY = 'members.record.count';

@Component({
  selector: 'app-members',
  templateUrl: 'members.component.html',
  styleUrls: ['members.component.css'],
  animations: [
    animations
  ]
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})

export class MembersComponent extends BaseComponent implements OnInit, OnDestroy {
  const
  @ViewChild('container') container: any;

  filterSettings: FilterSettings;
  gridSettings: GridSettings;
  showLoad: boolean = true;
  profiles: Profile[];
  questions: Question[];

  questionId: number = 0;
  scrollPositioin: any;

  constructor(
    private profileService: ProfileService,
    private titleEventsEmitter: TitleEventsEmitter,
    private questionService: QuestionService,
    public router: Router, private viewportScroller: ViewportScroller,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.filterSettings = new FilterSettings();
    this.filterSettings.gender = 0;
    this.filterSettings.distance = 0;

    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 12;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "FirstName";
    this.gridSettings.sortAsc = true;

    this.isLoading = true;
    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })

    router.events.pipe(filter((event: RouterEvent,): event is Scroll => event instanceof Scroll)
    ).subscribe(e => {
      this.scrollPositioin = e.position;
    });
  }

  ngOnInit() {
    this.titleEventsEmitter.broadcastEventTitleChanged("Members");
    this.currentProfile = this.authenticationService.getCurrentProfile();

    //Restore filter state
    const serializedState = sessionStorage.getItem(FILTER_STATE_KEY);
    console.log(serializedState, 'state..')
    if (serializedState) {
      this.filterSettings = JSON.parse(serializedState);
      const recordCount = sessionStorage.getItem(RECORD_COUNT_KEY);
      if (parseInt(recordCount)) {
        this.gridSettings.pageIndex = 0;
        this.gridSettings.pageSize = parseInt(recordCount);
      }
    }

    //this.titleEventsEmitter.broadcastEventTitleChanged("Account Details");
    this.filterSettings.profileId = this.currentProfile.id;
    this.questionService.GetForFilterSettings(this.filterSettings, 0, 1000, "ViewOrder", 1).subscribe(questions => {
      this.questions = questions;
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    const serializedState = JSON.stringify(this.filterSettings);
    sessionStorage.setItem(FILTER_STATE_KEY, serializedState);

    const recordCount = this.profiles.length;
    sessionStorage.setItem(RECORD_COUNT_KEY, recordCount.toString());

  }

  loadData() {
    this.filterSettings.profileId = this.currentProfile.id;
    console.log(this.filterSettings);
    this.setLoading(true);
    this.questionId = this.questions[Math.floor(this.questions.length * Math.random())].id;
    this.filterSettings.showApproved = true;
    this.profileService.GetForFilterSettingsPublic(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.profiles = data;
      this.profiles = this.profiles.filter(r => r.subscriptionStatus == 2);
      if (data.length < this.gridSettings.pageSize) { this.showLoad = false; } else { this.showLoad = true; }
      this.setLoading(false);

      sessionStorage.removeItem(RECORD_COUNT_KEY);
      sessionStorage.removeItem(FILTER_STATE_KEY);
      this.gridSettings.pageSize = 12;

      if (this.scrollPositioin) {
        this.viewportScroller.scrollToPosition(this.scrollPositioin);
      } else {
        this.viewportScroller.scrollToPosition([0, document.body.scrollHeight]);

      }
    });
  }

  loadMore() {
    if (!this.gridSettings.pageIndex) {
      this.gridSettings.pageIndex = 1;
    }
    this.gridSettings.pageIndex += 1;
    this.setLoading(true);
    this.filterSettings.showApproved = true;

    this.profileService.GetForFilterSettingsPublic(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      data.forEach(p => this.profiles.push(p));
      this.profiles = this.profiles.slice();
      this.setLoading(false);
      if (this.profiles.length < this.profiles[0].total) {
        this.showLoad = true
      } else this.showLoad = false;

    });
  }

  onFilterUpdated($event) {
    if ($event != null) {
      this.gridSettings.pageIndex = 0;
      this.filterSettings = $event;
      this.loadData();
    }
  }

  onQuestionUpdated() {
    //if ($event != null) {
    //  this.filterSettings = $event;
    //  this.loadData();
    //}
  }
}

//export class PasswordValidation {
//    static MatchPassword(AC: AbstractControl) {
//        let newPassword = AC.get('newPassword').value; // to get value in input tag
//        let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
//        if (newPassword != confirmPassword) {
//            console.log('false');
//            AC.get('confirmPassword').setErrors({ MatchPassword: true })
//        } else {
//            console.log('true');
//            return null
//        }
//    }
//}
