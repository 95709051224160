import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Profile } from '../../models/Profile';
//import { Employee } from '../_models';

@Injectable()
export class AuthenticationService {
  public isBrowser: boolean;
  //private subject = new Subject<any>();

  currentProfile: Profile;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  login(emailAddress: string, password: string): Observable<any> {
    if (this.isBrowser) localStorage.removeItem('currentProfile');
    return this.http.post('/v1/Auth', JSON.stringify({ emailAddress: emailAddress, password: password }), this.getHttpOptions()).pipe(map(data => {
      if (this.isBrowser) {
        localStorage.setItem('currentProfile', JSON.stringify(data));
        console.log(data, 'data....')
      };
      return of(data);
    }));
  }

  autoAuth(id: number): Observable<any> {
    if (this.isBrowser) localStorage.removeItem('currentProfile');
    return this.http.post('/v1/Auth/autoAuth/' + id, "", this.getHttpOptions()).pipe(map(data => {
      if (this.isBrowser) localStorage.setItem('currentProfile', JSON.stringify(data));

      return of(data);
    }));
  }

  authenticateProfileNew(tokenParamsTimeEncrypted: string): Observable<any> {
    if (this.isBrowser) localStorage.removeItem('currentProfile');
    return this.http.post('/v1/Auth/authenticateProfile', JSON.stringify({}), this.getHttpOptions(tokenParamsTimeEncrypted)).pipe(map(data => {
      if (this.isBrowser) localStorage.setItem('currentProfile', JSON.stringify(data));
      return of(data);
    }));
  }

  getTokenParams(tokenParamsTimeEncrypted: string): Observable<any> {
    return this.http.post('/v1/Auth/getTokenParams', JSON.stringify({}), this.getHttpOptions(tokenParamsTimeEncrypted));
  }

  sendResetEmail(emailAddress: string) {
    return this.http.post('/v1/Auth/sendResetEmail', JSON.stringify({ emailAddress: emailAddress, password: '' }), this.getHttpOptions());
  }

  UpdatePassword(id: number, oldPassword: string, newPassword: string): Observable<Profile> {
    return this.http.post<Profile>('/v1/Auth/updatePassword/' + id + "/" + (oldPassword == "" ? "-" : oldPassword) + "/" + newPassword, this.getHttpOptions());
  }

  resetPassword(tokenParamsTimeEncrypted: string, newPassword: string) {
    return this.http.post('/v1/Auth/resetPassword', JSON.stringify({ Password: newPassword }), this.getHttpOptions(tokenParamsTimeEncrypted));
  }

  logout() {
    //if (this.isBrowser) localStorage.removeItem('currentAccount');
    if (this.isBrowser) localStorage.removeItem('currentProfile');
    //this.subject.next();
  }

  isLoggedIn(): boolean {
    let mProfile: Profile = this.getCurrentProfile();
    return ((mProfile != null && mProfile.token != null && mProfile.token.length > 0));
  }

  //public getCurrentProfileObservable(): Observable<any> {
  //    return this.subject.asObservable();
  //}
  public getCurrentProfile(): any {
    //console.log('Before isBrow');
    if (this.isBrowser) {
      //console.log('After isBrow');
      // console.log(localStorage.getItem('currentProfile'), 'wkwkwkw')
      if (localStorage.getItem('currentProfile')) {
        // console.log(localStorage.getItem('currentProfile'), 'wkwkwkw')
        let c: Profile = new Profile();

        c = JSON.parse(<string>localStorage.getItem('currentProfile'));

        return c;
      } else {
        console.warn('currentProfile is null. Reloading page.');
      }
    }
    return null;
    //return JSON.parse(JSON.stringify(""));
  }

  private setCookie(name: string, value: string, expireDays: number, path = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  //HttpClient
  public getHttpOptions(tokenParamsTimeEncrypted: string = "") {
    let headerItems: HttpHeaders;
    let currentProfile: Profile = this.getCurrentProfile();
    this.currentProfile = this.getCurrentProfile();

    if (currentProfile && currentProfile.token) {
      //this.loginEventsEmitter.broadcastEventProfileLoginChanged(currentProfile);
      headerItems = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + currentProfile.token,
        'TokenParamsTimeEncrypted': tokenParamsTimeEncrypted
      });
    } else {
      headerItems = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'TokenParamsTimeEncrypted': tokenParamsTimeEncrypted
      });
    }

    return { headers: headerItems };
  }
}
