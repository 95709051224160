import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.css']
})
export class InlineEditComponent {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  editing: boolean = false;

  enableEdit() {
    this.editing = true;
  }

  onBlur() {
    this.editing = false;
    this.valueChange.emit(this.value);  // Emit updated value
  }

  onEnter() {
    this.editing = false;
    this.valueChange.emit(this.value);  // Emit updated value
  }
}
