import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './components/app/app.component';
import { NavMenuComponent } from './components/shared/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { CounterComponent } from './components/counter/counter.component';
import { FetchDataComponent } from './components/fetch-data/fetch-data.component';
import { AdminComponent } from './components/admin/admin.component';
import { ProfileService } from './core/services/profile.service';
import { Configuration } from './app.constants';
import { AuthenticationService } from './core/services/authentication.service';
import { Reg1LandingComponent } from './components/registration/reg-1-landing/reg-1-landing.component';
import { Reg2DetailsComponent } from './components/registration/reg-2-details/reg-2-details.component';
import { Reg3VerificationComponent } from './components/registration/reg-3-verification/reg-3-verification.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ActionVerifyEmailComponent } from './components/action/verify-email/verify-email.component';
import { Reg4PasswordComponent } from './components/registration/reg-4-password/reg-4-password.component';
import { Reg5VerifyProfileComponent } from './components/registration/reg-5-verifyProfile/reg-5-verifyProfile.component';
import { Reg6AboutYouComponent } from './components/registration/reg-6-aboutYou/reg-6-aboutYou.component';
import { Reg7BitMoreComponent } from './components/registration/reg-7-bitMore/reg-7-bitMore.component';
import { Reg8InterestsComponent } from './components/registration/reg-8-interests/reg-8-interests.component';
import { RoundPipe } from './core/pipes/round.pipe';
import { SafeUrlPipe } from './core/pipes/safe-url.pipe';
import { SafeHtmlPipe } from './core/pipes/safe-html.pipe';
import { IntersectionObserverAnimateDirective } from './core/directives/intersection-observer/intersection-observer-animate.directive';
import { Reg9PicturesComponent } from './components/registration/reg-9-pictures/reg-9-pictures.component';
import { Reg10TermsComponent } from './components/registration/reg-10-terms/reg-10-terms.component';
import { Reg11RegisteredComponent } from './components/registration/reg-11-registered/reg-11-registered.component';

import { MessagesComponent } from './components/messages/messages.component';
import { MessagesTestComponent } from './components/messages/test/test.component';
import { MessageService } from './core/services/message.service';
import { NotificationService } from './core/services/notification.service';

import { AdminProfilesComponent } from './components/admin/profiles/profiles.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterLinkDelayModule } from '@bcodes/ngx-routerlink-delay';

/*--NG-ZORRO----*/
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';


import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NguCarouselModule } from '@ngu/carousel';
import { PickerModule } from '@ctrl/ngx-emoji-mart';




import { UniversalCookieConsentModule } from 'universal-cookie-consent';
import { UiSwitchModule } from 'ngx-ui-switch';

import { AdminQuestionsComponent } from './components/admin/questions/questions.component';
import { AdminInterestsComponent } from './components/admin/interests/interests.component';
import { AdminTribesComponent } from './components/admin/tribes/tribes.component';
import { InterestService } from './core/services/interest.service';
import { TribeService } from './core/services/tribe.service';
import { QuestionService } from './core/services/question.service';
import { LogService } from './core/services/log.service';
import { AdminLogsComponent } from './components/admin/logs/logs.component';
import { AdminDashboardComponent } from './components/admin/dashboard/dashboard.component';
import { LoginEventsEmitter } from './core/events/login.events';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AccountComponent } from './components/account/account.component';

import { InviteComponent } from './components/invite/invite.component';
import { FilterComponent } from './components/shared/filter/filter.component';
import { MembersComponent } from './components/members/members.component';
import { MediaService } from './core/services/media.service';
import { GlobalService } from './core/services/global.service';
//import { MessageSendService } from './core/services/message-send.service';
import { EventsComponent } from './components/events/events.component';
import { NewsComponent } from './components/news/news.component';
import { NewsItemComponent } from './components/news//news-item/news-item.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { EventService } from './core/services/event.service';
import { DashboardService } from './core/services/dashboard.service';
import { LoaderComponent } from './components/shared/loader/loader.component';

import { EventEditComponent } from './components/events/event-edit/event-edit.component';
import { EventComponent } from './components/events/event/event.component';
import { AdminNewsItemsComponent } from './components/admin/news-items/news-items.component';
import { NewsItemsComponent } from './components/shared/news-items/news-items.component';
import { NewsItemService } from './core/services/news-item.service';
import { CarouselComponent } from './components/shared/carousel/carousel.component';
import { MemberComponent } from './components/members/member/member.component';
import { AdminSettingsComponent } from './components/admin/settings/settings.component';
import { TitleEventsEmitter } from './core/events/title.events';
import { InterestsComponent } from './components/shared/interests/interests.component';
import { AuthGuard } from './core/guards/auth-guard';
import { AppInjector } from './core/services/app-injector.service';
import { ActionJoinComponent } from './components/action/join/join.component';
import { MessagesThreadComponent } from './components/messages/message-thread/message-thread.component';
import { VideoChatComponent } from './components/shared/video-chat/video-chat.component';

import { VideoActivityIndicatorComponent } from './components/shared/video-chat/activity-indicator/activity-indicator.component';
import { VideoDeviceSelectComponent } from './components/shared/video-chat/settings/device-select.component';
import { VideoSettingsComponent } from './components/shared/video-chat/settings/settings.component';
import { VideoCameraComponent } from './components/shared/video-chat/camera/camera.component';
import { VideoParticipantsComponent } from './components/shared/video-chat/participants/participants.component';
import { VideoRoomsComponent } from './components/shared/video-chat/rooms/rooms.component';
import { DeviceService } from './components/shared/video-chat/services/device.service';
import { VideoChatService } from './components/shared/video-chat/services/videochat.service';
import { StorageService } from './components/shared/video-chat/services/storage.service';
import { AppRouting } from './app.routing';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { AdminEventsComponent } from './components/admin/events/events.component';
import { ProfileGraphComponent } from './components/shared/profile/profile-graph/profile-graph.component';
import { ProfileInterestsComponent } from './components/shared/profile/profile-interests/profile-interests.component';
import { ProfileQuestionsComponent } from './components/shared/profile/profile-questions/profile-questions.component';
import { ProfileListComponent } from './components/shared/profile/profile-list/profile-list.component';
import { ProfileCardComponent } from './components/shared/profile/profile-card/profile-card.component';
import { InviteListComponent } from './components/shared/event/invite-list/invite-list.component';
import { MediaGridComponent } from './components/shared/media/media-grid/media-grid.component';
import { MediaItemComponent } from './components/shared/media/media-item/media-item.component';
import { MediaListComponent } from './components/shared/media/media-list/media-list.component';
import { EventListComponent } from './components/shared/event/event-list/event-list.component';
import { EventInterestsComponent } from './components/shared/event/event-interests/event-interests.component';
import { EventCardComponent } from './components/shared/event/event-card/event-card.component';
import { NgxMasonryModule } from 'ngx-masonry';
/*import { AgmCoreModule } from '@agm/core';*/
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SubscriptionComponent } from './components/account/subscription/subscription.component';
import { CancelSubscriptionComponent } from './components/account/subscription/cancel/cancel-subscription.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';


import { LinkyModule } from 'ngx-linky';
import { NgChartsModule } from 'ng2-charts';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDividerModule } from 'ng-zorro-antd/divider';
//import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { HttpInterceptorService } from './core/http/http-interceptor.service';
import { CommentModalComponent } from './components/news/comment/comment-modal.component';
import { CommentItemComponent } from './components/news/comment-item/comment-item.component';
import { CommentsComponent } from './components/news/comments/comments.component';



const APP_PIPES = [RoundPipe, SafeUrlPipe, SafeHtmlPipe];

const antDesignIcons = AllIcons as { [key: string]: IconDefinition };
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    ...APP_PIPES,
    IntersectionObserverAnimateDirective,
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ActionVerifyEmailComponent,
    MessagesComponent,
    MessagesTestComponent,
    LoginComponent,
    LogoutComponent,
    AccountComponent,
    CancelSubscriptionComponent,
    InviteComponent,
    MembersComponent,
    MemberComponent,
    NewsComponent,
    CommentModalComponent,
    CommentItemComponent,
    CommentsComponent,
    EventsComponent,
    EventComponent,
    EventEditComponent,
    GalleryComponent,
    TermsComponent,
    PrivacyComponent,
    ResetPasswordComponent,
    SubscriptionComponent,

    NewsItemsComponent,
    NewsItemComponent,

    AdminComponent,
    AdminProfilesComponent,
    AdminInterestsComponent,
    AdminTribesComponent,
    AdminQuestionsComponent,
    AdminLogsComponent,
    AdminDashboardComponent,
    AdminNewsItemsComponent,
    AdminSettingsComponent,
    AdminEventsComponent,

    RegistrationComponent,
    Reg1LandingComponent,
    Reg2DetailsComponent,
    Reg3VerificationComponent,
    Reg4PasswordComponent,
    Reg5VerifyProfileComponent,
    Reg6AboutYouComponent,
    Reg7BitMoreComponent,
    Reg8InterestsComponent,
    Reg9PicturesComponent,
    Reg10TermsComponent,
    Reg11RegisteredComponent,
    AdminComponent,

    FilterComponent,
    ProfileInterestsComponent,
    ProfileQuestionsComponent,
    ProfileListComponent,
    ProfileQuestionsComponent,
    ProfileCardComponent,
    ProfileGraphComponent,
    InviteListComponent,
    MediaGridComponent,
    MediaItemComponent,
    MediaListComponent,
    MessagesThreadComponent,
    EventListComponent,
    EventInterestsComponent,
    EventCardComponent,
    CarouselComponent,
    //BaseComponent,
    InterestsComponent,
    ActionJoinComponent,


    VideoChatComponent,
    VideoRoomsComponent,
    VideoParticipantsComponent,
    VideoCameraComponent,
    VideoSettingsComponent,
    VideoDeviceSelectComponent,
    VideoActivityIndicatorComponent,

    LoaderComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgxGoogleAnalyticsModule.forRoot('G-M1ZFHFYPMV'),
    NgxGoogleAnalyticsRouterModule,
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,

    NzMenuModule,

    LinkyModule,
    NgChartsModule,
    InfiniteScrollModule,
    //VirtualScrollerModule,
    NgbModule,
    NzTableModule,
    NzCardModule,
    NzBreadCrumbModule,
    NzIconModule,
    NzStatisticModule,
    NzGridModule,
    NzFormModule,
    NzLayoutModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzMessageModule,
    NzSelectModule,
    NzDropDownModule,
    NzAvatarModule,
    NzBadgeModule,
    NzCheckboxModule,
    NzUploadModule,
    NzTabsModule,
    NzModalModule,
    NzTagModule,
    NzNotificationModule,
    NzProgressModule,
    NzAutocompleteModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSliderModule,
    NzDatePickerModule,
    NzPageHeaderModule,
    NzInputModule,
    NzDrawerModule,
    NzAlertModule,
    NzPopoverModule,
    NzSwitchModule,
    NzPaginationModule,
    NzEmptyModule,
    NzCollapseModule,
    NzAffixModule,
    NzToolTipModule,
    NzResultModule,
    NzTimePickerModule,

    //PasswordStrengthMeterModule,
    ImageCropperModule,
    NzDividerModule,


    NguCarouselModule,
    NgxMasonryModule,
    //AgmCoreModule.forRoot({
    //  apiKey: 'AIzaSyAQROnF73az2l80OeAm4uF2Mlhuk9jQ5Zw',
    //  libraries: ["places"],
    //  apiVersion: 'quarterly'
    //}),
    UniversalCookieConsentModule.forRoot({
      autoShow: true,
      logoUrl: 'assets/img/logo-280.png',
      acceptText: 'Accept Cookies',
      consentTypes: [
        {
          id: 'base',
          title: 'Base Functionality',
          description: 'These cookies are required for the functionality of this website and can\'t be disabled.',
          mandatory: true
        },
        {
          id: 'analytics',
          title: 'Analytics',
          description: 'We use these cookies to improve our website.',
          color: 'orange'
        }
      ],
      disableBodyScroll: false,
      introText: 'We don\'t use cookies to track user activity and we don\'t send any cookies to advertisers. In fact, we don\'t work with any advertisers at all! However, our payments system does use one cookie and so we require that you accept this before you continue. No personal data is shared with any 3rd parties without your permission and we take privacy very seriously.',
      customizeIntroText: 'Use the following settings to personalize which cookies you want to allow.',
      cookieSettings: {
        expires: 365
      }
    }),
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0, 189, 99)',
      switchColor: '#80FFA2',
      defaultBgColor: '#00ACFF',
      defaultBoColor: '#476EFF',
      checkedLabel: 'on',
      uncheckedLabel: 'off'
    }),

    DragDropModule,
    PickerModule,
    RouterLinkDelayModule,


    AppRouting,

    BrowserAnimationsModule,

    NgbModule,


  ],
  providers: [
    Configuration,
    AuthenticationService,
    ProfileService,
    MessageService,
    NotificationService,
    //MessageSendService,
    InterestService,
    QuestionService,
    LogService,
    MediaService,
    GlobalService,
    EventService,
    NewsItemService,
    TribeService,
    DashboardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },

    DeviceService, VideoChatService, StorageService,

    LoginEventsEmitter,
    TitleEventsEmitter,

    AuthGuard,

    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }

}
