import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { Profile } from '../../models/Profile';
import { AuthenticationService } from '../services/authentication.service';
import { ProfileService } from '../services/profile.service';

@Injectable()
export class AuthGuard {
  isBrowser: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private profileService: ProfileService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isBrowser) {
      let mProfile: Profile = this.authService.getCurrentProfile();


      return this.getActivationResponse(mProfile, route, state);

      //if (route.url.length > 0 && route.url.toString().indexOf("subscription") >= 0) {
      //  this.profileService
      //    .Get(mProfile.id)
      //    .subscribe(
      //      data => {
      //        localStorage.setItem('currentProfile', JSON.stringify(data));
      //        mProfile = data;
      //        //this.loaderService.hide();
      //        //this.ngProgress.complete();
      //        return this.getActivationResponse(mProfile, route, state);
      //      },
      //      error => console.log(error),
      //      () => { }
      //    );


      //} else {
      //  return this.getActivationResponse(mProfile, route, state);
      //}

    }
  }

  getActivationResponse(mProfile: Profile, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isSuperUser: boolean = mProfile?.userLevel == 3;
    let isNormalUser: boolean = mProfile?.userLevel == 1;
    console.log(mProfile, 'wwwww')

    if (mProfile == null) {
      //is logged out
      this.router.navigate(['/login/']);
    } else if (mProfile.status == 3) {
      //is blocked
      this.router.navigate(['/login/']);
    } else if (!mProfile.emailConfirmed) {
      //not confirmed email
      this.router.navigate(['/registration/3']);
    } else if (mProfile.status != 2) {
      //is not approved

      this.router.navigate(['/registration/5']);


    } else if (mProfile && (mProfile.subscriptionStatus != 2)) { //.add(1, 'days')// || !moment(mProfile.subscriptionExpiry, 'MM/D/YYYY').isAfter(moment(Date.now(), 'MM/D/YYYY'))

      //is not subscribed
      if (!isSuperUser) {
        if (route.url.toString().indexOf("subscription") < 0 && route.url.toString().indexOf("account") < 0) {
          console.log('sub..sub..', mProfile)
          if (mProfile.stripeCustomerID) {
            this.router.navigate(['/account/subscription/canceled']);
          } else
            this.router.navigate(['/account/subscription']);
        }
      }
    } else {
      //page level redirects
      if (route.url.toString().indexOf("tribes") >= 0 && !isSuperUser) {
        this.router.navigate(['/admin']);
      }
      if (route.url.toString().indexOf("admin") >= 0 && isNormalUser) {
        this.router.navigate(['/admin']);
      }

    }


    if (mProfile == null || (mProfile.status != null && mProfile.status != 2)) {

      //if (!this.authService.allowAccess(2)) {
      // not logged in so redirect to login page with the return url
      if (mProfile == null) { }
      if (mProfile.status != null && mProfile.status != 2) {
        if (mProfile.emailConfirmed) {

        } else {

        }

      } else {


        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });

      }

      return false;
    }


    return true;
  }

}
