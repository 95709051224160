import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Profile } from 'src/app/models/Profile';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentProfile: Profile | null = this.authenticationService.getCurrentProfile();
        let clonedRequest = req.clone();
        // console.log(currentProfile, 'currrent profile..')
        if (currentProfile !== null) {
            clonedRequest = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + currentProfile.token)
            });
        }

        return next.handle(clonedRequest).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 && !req.url.includes('/login')) {
                    // this.router.navigate(['/login']);
                    // window.location.reload();
                }

                console.error('Error intercepted', error);
                return throwError(error);
            })
        );
    }
}