import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Profile } from '../../models/Profile';
import { LoginEventsEmitter } from '../../core/events/login.events';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { ProfileService } from '../../core/services/profile.service';
import { GlobalService } from '../../core/services/global.service';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  //public profile: Profile = new Profile();
  returnUrl: string;
  isLoggingIn: boolean = false;
  validateForm!: UntypedFormGroup;
  error: string;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private titleEventsEmitter: TitleEventsEmitter,
    private profileService: ProfileService,
    private loginEventsEmitter: LoginEventsEmitter
  ) {
    super();
  }

  ngOnInit() {
    this.titleEventsEmitter.broadcastEventTitleChanged("Login");
    this.validateForm = this.fb.group({
      emailAddress: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
    this.setLoading(false);
  }

  openLinkInNewTab(): void {
    const url = 'https://the-otto-connection.smartmatchapp.com/client/submissionform/17/';
    window.open(url, '_blank');
  }

  submitForm(): void {
    this.authenticationService.login(this.validateForm.controls['emailAddress'].value, this.validateForm.controls['password'].value).subscribe(
      data => {
        let mProfile: Profile = this.authenticationService.getCurrentProfile();
        if (mProfile != null && mProfile.token) {
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.loginEventsEmitter.broadcastEventProfileLoginChanged(mProfile);
          if (this.returnUrl == undefined) {
            this.returnUrl = '/';
          }

          this.gaService.event(this.analytics.auth.login, this.analytics.auth.category, mProfile.id.toString())

          this.router.navigate([this.returnUrl]);

        }
      },
      error => {
        if (error.status == 404) {
          this.error = "Email or password not found"
        } else {
          this.error = "Error logging in"
        }
        this.notify("error", this.error);
      });
  }
  //for (const i in this.validateForm.controls) {
  //  this.validateForm.controls[i].markAsDirty();
  //  this.validateForm.controls[i].updateValueAndValidity();
  //}
}
