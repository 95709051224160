import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { NzTableComponent, NzTableQueryParams } from 'ng-zorro-antd/table';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import * as moment from 'moment';
import { Tribe } from '../../../models/Tribe';
import { TribeService } from '../../../core/services/tribe.service';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { QuestionService } from '../../../core/services/question.service';
import { Question } from '../../../models/Question';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'admin-profiles',
  styleUrls: ['./profiles.component.css'],
  templateUrl: './profiles.component.html',
})
export class AdminProfilesComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('nzTable', { static: false }) nzTable: NzTableComponent<any>;
  private httpClient: HttpClient;
  private searchSubject = new Subject<string>();
  currentCust: number = 0;
  public profiles: Profile[];
  public tribes: Tribe[];
  public profilesDisplay: Profile[];
  public tribesDisplay: Tribe[];
  public questions: Question[];
  error: string;

  moment: any = moment;
  gridSettings: GridSettings;
  filterSettings: FilterSettings;
  public subscriptions: any[] = [];
  profileStatus = 2;
  searchPhrase = null;
  tribeSearchPhrase = null;
  profileSearchResults: Profile[];
  tribeSearchResults: Tribe[];
  nzFilterOption = () => true;
  customers: any[] = [];
  subs: any[] = [];
  isEditingTribeFilter = false;
  filterTribe: Tribe = null;
  isModalInviteVisible = false;
  editInvitingId = 0;
  editProfileId = 0;
  editTribeId = 0;
  newLoad = true;
  total = 1;
  pageSize: number;


  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private questionService: QuestionService,
    private tribeService: TribeService,
    private http: HttpClient,
    private location: Location
  ) {
    super();
    this.httpClient = http;
    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 20;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;
    this.filterSettings = new FilterSettings();

  }

  ngOnInit() {
    this.filterSettings.keyword = "";
    this.filterSettings.showOnlyUnapproved = true;
    this.loadData();
    this.loadTribes();
    this.setupSearch();

  }

  setupSearch() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.performSearch(value);
    });
  }

  getAge(dateOfBirth: string): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  loadTribes() {
    const pageSize = this.gridSettings.pageSize;
    const { pageIndex, sortField, sortAsc } = this.gridSettings;
    this.tribeService.GetAll('', pageIndex, pageSize, sortField, sortAsc ? 1 : -1).subscribe(tribes => {
      this.tribes = tribes;
      this.tribesDisplay = tribes;
      this.setLoading(false);
      this.searchTribes('');
    });
  }

  loadData() {
    const pageSize = this.gridSettings.pageSize;
    const { pageIndex, sortField, sortAsc } = this.gridSettings;

    forkJoin([
      this.profileService.GetForAdmin(this.filterSettings, pageIndex, pageSize, sortField, sortAsc ? 1 : -1)
    ]).subscribe(([data]) => {
      this.filterSettings.showOnlyUnapproved = false;
      this.filterSettings.showApproved = false;

      this.profiles = data.profiles.filter(x => this.filterTribe ? x.tribeId === this.filterTribe.id : x.tribeId > -1);
      this.profilesDisplay = this.profiles;
      this.total = data.total;
      console.log(this.total, this.profilesDisplay, 'profile and dara')

      if (this.newLoad) {
        this.filterSettings.showOnlyUnapproved = false;
        this.filterSettings.keyword = '';
        this.newLoad = false;
      }

      this.setLoading(false);
    });
  }


  selectForEdit(data) {
    this.editInvitingId = data.id;
  }

  selectTribe(data) {
    this.editTribeId = data.id;

  }

  selectTribeFilter() {
    this.isEditingTribeFilter = !this.isEditingTribeFilter;
  }

  getImageUrl = (p: Profile): string => { return this.globalService.getProfileImageUrl(p, 256); }



  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    this.pageSize = pageSize;
    if (!pageIndex)
      return;

    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.gridSettings.pageIndex = pageIndex;
    this.gridSettings.pageSize = pageSize;
    if (sortField) {
      this.gridSettings.sortField = this.globalService.capitalize(sortField);
      this.gridSettings.sortAsc = sortOrder == "ascend";

    }
    this.loadData();
  }

  setStatus(p, status) {
    console.log(p, status, 'sss')
    this.setLoading(true);
    p.status = status;
    this.profileService.Update(p).subscribe(data => {
      p = data;
      this.setLoading(false);
    });
  }

  approveProfile(p: Profile) {
    console.log(p, 'profile for approved')
    this.setLoading(true);
    this.profileService.ApproveProfile(p.id).subscribe((data) => {
      p = data; ``
      this.setLoading(false);
    })
  }
  delete(p) {
    this.setLoading(true);
    this.profileService.Delete(p.id).subscribe(data => {
      this.loadData();
    });
  }

  cancelDelete(): void {
    //this.nzMessageService.info('Cancelled delete.');
  }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }

  getYears = (d: Date): string => { return moment().diff(d, 'years').toString(); }

  searchProfiles(value: string): void {

    if (value.length > 1) {
      this.filterSettings.showOnlyUnapproved = false;
      this.isLoading = true;
      this.filterSettings.keyword = value;
      this.profileService.GetForFilterSettings(this.filterSettings, 0, 1000, "", 1)
        .subscribe(data => {
          this.profiles = data;
          this.profilesDisplay = this.profiles;
          this.isLoading = false;
        });
    }
  }

  performSearch(value: string): void {
    if (value.length > 1) {
      this.filterSettings.showOnlyUnapproved = false;
      this.isLoading = true;
      this.filterSettings.keyword = value;
      this.loadData();
    }
    //  this.profileService.GetForFilterSettings(this.filterSettings, 0, 1000, "", 1)
    //    .subscribe(data => {
    //      this.profiles = data;
    //      this.profilesDisplay = this.profiles;
    //      this.isLoading = false;
    //    });
    //} else {
    //  if (value.length == 0) {
    //    this.filterSettings.showOnlyUnapproved = true;
    //    this.loadData();
    //  }
    //}
  }

  searchKeywordProfiles(value: string): void {
    this.searchSubject.next(value);
  }

  searchTribes(value: string): void {
    if (value.length > 0) {
      this.isLoading = true;
      this.tribeService.GetAll(value, 0, 10, "", 1)
        .subscribe(data => {
          this.tribeSearchResults = data;

          this.isLoading = false;
        });
    }
  }

  onStatusChanged(status, id) {
    let p = this.profiles.find(x => x.id == id);
    p.status = status;
    if (p.status == 4) {
      p.firstName = "Anon";
      p.surname = "Ymous";
      p.generalNotifications = false;
      p.textNotifications = false;
      this.setLoading(true);
      //let fileToUpload = base64ToFile(this.croppedImage);
      const formData = new FormData();

      this.httpClient.get('assets/anon.jpg', { responseType: 'blob' as 'json' })
        .subscribe(data => {

          /*const blob = new Blob([data], { type: 'image/jpg' });*/

          formData.append('file', <any>data, "image.jpg");
          this.http.post('/v1/Profile/uploadPhoto/' + id, formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {

              if (event.type === HttpEventType.Response) {

                this.profileService.Update(p).subscribe(data => {
                  this.msgService.success("Profile Updated")
                  this.setLoading(false);
                });
              }
            });
        }
        );



    }

  }


  onSearchChanged(value: number) {
    console.log('loading7 data...')
    //this.selectProfile(value);
    let p = this.profiles.find(x => x.id == this.editInvitingId);
    p.invitedBy = value;
    this.profileService.Update(p).subscribe(data => {
      this.loadData();
    });
  }

  onTribeSearchChanged(value: number,) {
    //this.selectProfile(value);
    console.log('loading data.55..')

    let p = this.profiles.find(x => x.id == this.editTribeId);

    p.tribeId = value;
    this.profileService.Update(p).subscribe(data => {

      this.loadData();
    });

  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  autoAuth(id: number) {
    this.authenticationService.autoAuth(id).subscribe(
      data => {
        let mProfile: Profile = this.authenticationService.getCurrentProfile();

        if (mProfile != null && mProfile.token) {
          this.profileService.Get(mProfile.id).subscribe(data => { //update from api

            this.currentProfile = data;

            localStorage.setItem('currentProfile', JSON.stringify(data));
          });
          window.location.href = "/";

          //this.router.navigate(["/"]);
          //window.location.reload();

        }
      },
      error => {
        if (error.status == 404) {
          this.error = "Email or password not found"
        } else {
          this.error = "Error logging in"
        }
        this.notify("error", this.error);
      });
  }


  block(id: number) {
    this.profileService.Block(id).subscribe(() => {
      this.notify("success", "User blocked", "Success");
    }, err => {

    });
  }

  onTribeSearchFilterChanged(value: number,) {
    console.log('loading data99...')
    //this.selectProfile(value);

    //let p = this.profiles.find(x => x.id == this.editTribeId);

    //p.tribeId = value;
    //this.profileService.Update(p).subscribe(data => {

    //  this.loadData();
    //});

    this.tribeService.GetAll("", 0, 10, "", 1)
      .subscribe(data => {
        this.filterTribe = data.filter(x => x.id == value)[0];
        this.isEditingTribeFilter = false;
        this.loadData();
      });

  }

  restore() {
    //this.profileService.RestoreProfiles().subscribe(data => {
    //  console.log(data);
    //});
  }

  showModalInvite(emailAddress: string, custId: number): void {
    this.subs = [];
    this.http.get<any>("/v1/payments/get-subscriptions-by-email?customerEmail=" + emailAddress).subscribe(data => {
      this.currentCust = custId;
      this.customers = data;
      console.log(data);
      this.customers.forEach(r => {

        this.http.get<any>("/v1/payments/get-subs-list-by-id?customerId=" + r.id).subscribe(subData => {
          console.log(subData);
          console.log(this.currentProfile);
          this.subs = this.subs.concat(subData);
          console.log(this.subs);
        });

      });


      this.isModalInviteVisible = true;

    });

  }

  linkSub(profileId: string, stripeCustomerId: string) {
    console.log(profileId, stripeCustomerId)
    this.setLoading(true);
    this.http.get<any>("/v1/payments/link-subscription?profileId=" + this.currentCust + "&customerId=" + stripeCustomerId).subscribe(data => {
      console.log(data);
      this.profileService.Get(+profileId).subscribe(rdata => {
        this.searchKeywordProfiles(this.filterSettings.keyword);
        this.showModalInvite(rdata.emailAddress, this.currentCust);
      }
      );

    }, (error => {
      console.log(error);

    }));

  }

  handleModalInviteOk(): void {


    setTimeout(() => {
      this.isModalInviteVisible = false;

    }, 500);
  }
  handleModalInviteCancel(): void {
    this.isModalInviteVisible = false;
  }



  refreshSub(customerId: string) {

    this.http.get<any>("/v1/payments/refresh-subscription?customerId=" + customerId).subscribe(data => {
      this.subscriptions = data;
      console.log(data);
      this.subscriptions = this.subscriptions.filter(r => r.status = 'active');
      if (this.subscriptions.length > 1 || this.subscriptions.length == 0) {
        this.notify("error", "This user either has multiple active subscriptions or no active subscription, please contact Tech Support", "Subscription Not Refreshed");
      } else {
        this.notify("success", "Subscription Refreshed", "Success");

      }
      this.setLoading(false);
    })
  }

  cancel(id: string, profileId: string) {
    this.setLoading(true);
    console.log(id, profileId)
    //this.http.get<any>("/v1/payments/get-subscriptions?customerId=" + id).subscribe(data => {
    //  this.subscriptions = data;

    //  this.subscriptions = this.subscriptions.filter(r => r.status = 'active');
    //  if (this.subscriptions.length > 1) {
    //    this.notify("error", "This user has multiple active subscriptions, please contact Tech Support", "Subscription Not Cancelled");
    //  } else if (this.subscriptions.length == 0) { this.notify("error", "This user has no active subscriptions, please contact Tech Support", "Subscription Not Cancelled"); }

    //  else {

    this.http.get<any>("/v1/payments/cancel-subscription?subscriptionId=" + id + "&profileId=" + profileId).subscribe(data => {
      this.searchKeywordProfiles(this.filterSettings.keyword);

      this.notify("success", "Subscription will expire on " + moment(data.currentPeriodEnd).format('Do MMMM YYYY'), "Subscription Cancelled");
      this.setLoading(false);
    }, (error => {
      this.notify("error", "Subscription not cancelled. Please contact support.");
    }));





    //  }


    //})




  }


}



//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
